*{
  font-family: 'Titillium Web', sans-serif; 
  margin: 0;
  padding: 0; 
  box-sizing: border-box; 
}


html,
body,
.intro {
  font-family: 'Amazon Ember';
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.allcontent{
  min-height: 100vh;
  margin-bottom: 0;
}


/* Loader */
.loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  width: 80px;
  height: 80px;
  padding-left: 0;
}
.loader:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #f65606;
  border-color: #f65606 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*navigation bar*/
#navigation{
  background-color: #000;
  position: sticky;
  top: 0;
}

#nav-text{
  color: #ffffff;
  margin-left: 10px;
}

#nav-text:hover{
  color:#f65606;
}


/*footer*/
#footer{
  background-color: #000;
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
}

#footer > * {
  width:100%;
  padding:1rem;
}

/* tablet breakpoint */
@media (min-width:768px) {
  #footer > * {
      width:calc(100% / 3);
  }
}

.phone-no:hover{
  color: #ffffff;
}


.map-front {
  margin: 0 auto 4rem;
  text-align: center;
}

/*carousel 3*/
#carouselsection{
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(246,86,6,1) 100%, rgba(255,255,255,1) 100%);
}

#btn-left{
  background-color: #f65606;
}
#btn-left:hover{
  background-color: #FFA500;
}

#btn-right{
  background-color: #f65606;
}

#btn-right:hover{
  background-color: #FFA500;
}

.carousel{
  margin:10px 10px 60px 10px;
}

.carousel-inner {
  max-height: 700px;
  img {
    max-height: 700px;
  }
}
 
/*home package*/
.pack-title {
  margin: 0 auto 4rem;
  text-align: center;
}

.package{
  background: linear-gradient(180deg, rgba(246,86,6,1) 50%, rgba(255,255,255,1) 100%);
  margin-top: 0;
}

.pack-title{
  font-size: 50px;
  font-weight: 1400;
}


.card-pack {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 320px;
  margin: 3rem 1rem;
  text-align: center;
  font-family: arial;
  background-color: #ffffff;
}

.card-pack i {
  color: #f65606;
  height: 60px;
  width: 60px;
  font-size: 28px;
  border-radius: 50%;
  margin-top: 25px;
}

.price {
  color: grey;
  font-size: 22px;
}

.card-pack button{
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 95%;
  font-size: 18px;
}

.card-pack button:hover {
  opacity: 0.7;
  background-color:#f65606;
}

/*homepage*/

/*page1*/
.hero{
  width: 100%;
  min-height: 100vh;
  padding: 10px 10%;
  position: relative;
  background-image: url('./back1.jpg');
  background-repeat:no-repeat;
  background-position:center;
  background-size: 100% 100%;
  margin: 1px auto 0;
 
}


.banner-discount{
  width: 100%;
  height: 100vh;
  padding: 10px 10%;
  position: relative;
  background-image: url('./discount_page.jpg');
  background-repeat:no-repeat;
  background-size: 100% 100%;
  margin: auto;
 
}


.banner-userapp{
  width: 80%;
  height: 100vh;
  padding: 10px 10%;
  position: relative;
  background-image: url('./userApp.png');
  background-repeat:no-repeat;
  background-size: 100% 100%;
  margin: auto;
 
}


.banner-driverapp{
  width: 80%;
  height: 100vh;
  padding: 10px 10%;
  position: relative;
  background-image: url('./driverApp.png');
  background-repeat:no-repeat;
  background-size: 100% 100%;
  margin: auto;
 
}


.banner-taxiapp{
  width: 100%;
  height: 100vh;
  padding: 10px 10%;
  position: relative;
  background-image: url('./taxiapp.png');
  background-repeat:no-repeat;
  background-size: 100% 100%;
  margin: auto;
 
}

.logoImg img{
 
  margin-left: 0;
  height:200px;
  width:200px;
  margin:0 ;

  
}


.logo-car{
  align-items: center;
}

.content {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content h1{

  color: #222;
  font-size: 50px;
  text-align: center;
  
}
.content h3{

  color: #ffffff;
  font-size: 40px;
  text-align: center;
  
}

.btn-get{
  display: inline-block;
  text-decoration: none;
  padding: 14px 40px;
  color: #fff;
  background-color:#f65606;
  font-size: 14px;
  border-radius: 30px;
  margin: 10px;

}

.btn-get:hover{
  background-color: #000;
  color: #ffffff;
}
.start-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.feature-img{
  margin-left: 0;
  left: 5%;
  margin: 0;
}

.anim{
  opacity: 0;
  transform: translateY(30px);
  animation: moveup 0.5s linear forwards;
}

@keyframes moveup{
  100%{
    opacity: 1;
    transform: translateY(0px);
  }
}
/*page4*/
.details {
  width: 100%;
  background: #3c3c41;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8rem 15rem;
}

.details h3,
h6 {
  color: #fff;
}

.details .one {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.details .one h3 {
  font-size: 2.5rem;
  margin-bottom: 1.1rem;
}

.details .one h6 {
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1.2rem;
}


.details .one .btn-about {
  color: #fff;
  background: #f65606;
  padding: 1rem 0;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
}

.details .one .btn-about:hover {
  color: #f65606;
  background: #fff;
}
.details .two {
  width: 45%;
  border-left: 1px solid #666262;
  padding-left: 4rem;
}

.details .two h3 {
  font-size: 2.5rem;
}

.details .two h6 {
  font-size: 1.4rem;
}

.details .two h6 span{
  font-size: 1.4rem;
  color:#f65606 ;
}


/*app page*/



.userapp {
  width: 100%;
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(246,86,6,1) 100%, rgba(255,255,255,1) 100%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8rem 15rem;
  height: auto;
}

.userapp h3 {
  color: #000;
  font-weight: bold;
  text-align: center;
}

.userapp .one {
  width: 45%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.userapp .one h3 {
  font-size: 2.5rem;
  margin-bottom: 1.1rem;
}


.userapp .one .btn-about {
  width: 80%;
  color: #fff;
  background: #f65606;
  padding: 1rem 0;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  border-radius: 10px;

}


.userapp .one .btn-about:hover {
  color: #f65606;
  background: #fff;
}


.userapp .two {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}


.userapp .two .btn-about1 {
  width: 80%;
  color: #fff;
  background: #f65606;
  padding: 1rem 0;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  border-radius: 10px;
}

.userapp .two .btn-about1:hover {
  color: #f65606;
  background: #fff;
}


.userapp .two h3 {
  font-size: 2.5rem;
}

/*taxi app*/


.taxiapp {
  width: 100%;
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(246,86,6,1) 100%, rgba(255,255,255,1) 100%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8rem 15rem;
  height: auto;
}

.taxiapp h3 {
  color: #000;
  font-weight: bold;
  
}

.taxiapp .one {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}
.taxiapp .one h3 {
  font-size: 2.5rem;
 
}


.taxiapp .two {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 
 
}



.taxiapp .two h3 {
  font-size: 2.5rem;
}


.taxiapp .two .user {
  margin-bottom: 50px;
}


.taxiapp .two .user .playstore{
 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}



.taxiapp .two .driver .playstore{
 
  display: flex;
  flex-direction: row;
  justify-content: space-between;

 
}







/*page5*/
.trainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8rem 8rem;
  background: linear-gradient(90deg, rgba(246,86,6,1) 3%, rgba(255,255,255,1) 62%);
  
}

.trainer .about {
  width: 30%;
  
}

.trainer .about h2 {
  color: #000;
  font-size: 2.2rem;
  font-weight: 900;
  padding-bottom: 1.8rem;
}

.trainer .about p {
  color: #000;
  font-size: 1.1rem;
  text-align: justify;
  font-weight: 600;
}

.trainer .people {
  width: 65%;
}

.trainer .people {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.trainer .people .team1 {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
 
}

.trainer .people .team1 .teamDetails {
  
  height: auto;
  width: 100%;
  box-sizing: border-box;
  background: #f65606;
  box-shadow: 0 1.6px 3.6px rgba(0, 0, 0, 0.01),
    0 4.4px 10px rgba(0, 0, 0, 0.015), 0 10.6px 24.1px rgba(0, 0, 0, 0.02),
    0 35px 80px rgba(0, 0, 0, 0.03);
  margin: 10px;
  padding: 10px;
}
.trainer .people .team1 .teamDetails .name {
  padding: 5px 20px 0 20px;
}
.trainer .people .team1 .teamDetails .name h4 {
  color: #fff;
  font-size: 1.2rem;
}


.trainer .people .team2 {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}


.trainer .people .team2 .teamDetails {
 
  height: auto;
  width: 100%;
  box-sizing: border-box;
  background: #f65606;
  box-shadow: 0 1.6px 3.6px rgba(0, 0, 0, 0.01),
    0 4.4px 10px rgba(0, 0, 0, 0.015), 0 10.6px 24.1px rgba(0, 0, 0, 0.02),
    0 35px 80px rgba(0, 0, 0, 0.03);
  padding: 10px;
  margin: 10px;
}
.trainer .people .team2 .teamDetails .name {
  padding: 5px 20px 0 20px;
}
.trainer .people .team2 .teamDetails .name h4 {
  color: #fff;
  font-size: 1.2rem;
}



/*page6*/
#class {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

#class .h2 {
  font-size: 2.2rem;
  font-weight: 700;
  color: #222;
  text-align: center;
  padding-bottom: 50px;
}

#class .row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#class .row .team1 {
  width: 325px;
  border-radius: 8px;
  overflow:hidden;
  background: #fff;
  box-shadow: 0 1.6px 3.6px rgba(0, 0, 0, 0.01),
    0 4.4px 10px rgba(0, 0, 0, 0.015), 0 10.6px 24.1px rgba(0, 0, 0, 0.02),
    0 35px 80px rgba(0, 0, 0, 0.03);
  margin: 10px;
  margin-bottom: 20px;
}

#class .row .team1  img {
  width: 100%;
  height: 200px;

}


#class .row .team1   h4 {
  color: #212529;
  font-size: 1.7rem;
  text-align: center;
}



#class .row .team1 p {
  color: #666262;
  font-size: 1.1rem;
  text-align: justify;
  padding: 10px;
}

/*page7*/
.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8rem;
  background: #f1f1f1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.contact .form {
  width: 45%;
}
.contact .form h2 {
  font-size: 2rem;
  color: #212529;
  padding-bottom: 10px;
  width: 100%;
}

.contact .form .submit {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.contact .form .submit input,
textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #95a5a6;
  outline: none;
  margin: 13px 0;
}
.contact .form .submit input:focus,
textarea:focus {
  border: 1px solid #111;
}

.contact .form .submit input::placeholder {
  color: #7f8c8d;
  font-size: 1.1rem;
}
.contact .form .submit textarea {
  color: #7f8c8d;
  font-size: 1.1rem;
  height: 80%;
}

.contact .form .btn-send {
  width: 100%;
  padding: 15px 0;
  outline: none;
  margin: 13px 0;
  text-decoration: none;
  color: #fff;
  background: #f65606;
  text-align: center;
  font-size: 1.1rem;
}

.contact .form .btn-send:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.contact .map {
  width: 45%;
}

.contact .map h2 {
  font-size: 2rem;
  color: #212529;
  padding-bottom: 10px;
}

.contact .map p {
  font-size: 1.1rem;
  color: #212529;
  padding-bottom: 30px;
}
.contact .map p i {
  color: red;
  padding-right: 4px;
}

.contact .map .contact-box {
  width: 200%;
  height:320px;
  background-color: #f65606;
}

.contact .map .contact-box h1{
  padding: 50px 5px 5px 50px;
  color: #ffffff;
  font-size: 2rem;
  width: 100%;
}

/*demo video*/
.demo1{
  display: flex;
  height: auto;
  background-color: #3c3c41;
}
.process1{
  flex: 1;
  height: auto;
  margin: 20px 50px;
  text-align: justify;
 
}
.process1 h3{
  font-size: 2.5rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
}

.process1 h6{
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1.2rem;
}

.frame1{
  flex: 1;
  height: auto;
  border-left: 1px solid #666262;
  display: flex;
  justify-content: center;
  align-items: center;
}


.demo2{
  display: flex;
  height: auto;
  background-color: #3c3c41;
}
.process2{
  flex: 1;
  height: auto;
  margin: 20px 50px;
  text-align: justify;
 
}
.process2 h3{
  font-size: 2.5rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
}

.process2 h6{
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1.2rem;
}

.frame2{
  flex: 1;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #666262;
}

.demo3{
  display: flex;
  height: auto;
  background-color: #3c3c41;
}
.process3{
  flex: 1;
  height: auto;
  margin: 20px 50px;
  text-align: justify;
 
}
.process3 h3{
  font-size: 2.5rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
}

.process3 h6{
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1.2rem;
}

.frame3{
  flex: 1;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #666262;
}

.mapsample{
  display: flex;
  height: auto;
  background-color: #f1f1f1;
}
.discount{
  flex: 1;
  height: auto;
  margin: 20px 50px;
  text-align: justify;
}
.mapframe{
  flex: 1;
  height: auto;
  border-left: 1px solid #666262;
  display: flex;
  justify-content: center;
  align-items: center;
}



@media screen and (max-width: 1500px) {
  .banner-discount{
    height: calc(100vh/2);
  }

  .banner-userapp{
    height: calc(100vh);
  }

  .banner-driverapp{
    height: calc(100vh);
  }


   
  .taxiapp {
    padding: 6rem 7rem;
  }
  .taxiapp .one {
    width: 48%;
  }

  .taxiapp .one h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }


  .taxiapp .two {
    width: 48%;
  }

  .taxiapp .two h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

.trainer {
  padding: 4rem 1rem;
}

.trainer .about {
  width: 100%;
}

.trainer .people {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.trainer .people .team1 {
  width: 100%;
}

.trainer .people .team2 {
  width: 100%;
}

.contact .map .contact-box {
  width: 120%;
  height:320px;
 
}

.contact .map .contact-box h1{
  padding: 30px 5px 5px 30px;
  
}


}
@media screen and (max-width: 991px) {
  
  .banner-discount{
    height: calc(100vh/2);
  }

  .banner-userapp{
    height: calc(100vh);
  }

  .banner-driverapp{
    height: calc(100vh);
  }


  .details {
    padding: 6rem 7rem;
  }
  .details .one {
    width: 50%;
  }

  .details .one h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .details .one h6 {
    font-size: 1.3rem;
    font-weight: 400;
    margin-bottom: 1.2rem;
  }


  .details .one a {
    font-size: 0.9rem;
  }

  .details .two {
    padding-left: 3.5rem;
  }

  .details .two h3 {
    font-size: 2rem;
  }

  .details .two h6 {
    font-size: 1rem;
  }


  
  .userapp {
    padding: 6rem 7rem;
  }
  .userapp .one {
    width: 50%;
  }

  .userapp .one h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }


  .userapp .two {
    width: 48%;
  }

  .userapp .two h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }


  
  .taxiapp {
    padding: 6rem 7rem;
  }
  .taxiapp .one {
    width: 48%;
  }

  .taxiapp .one h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }


  .taxiapp .two {
    width: 48%;
  }

  .taxiapp .two h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

 
  .trainer {
    padding: 4rem 1rem;
  }

  .trainer .about {
    width: 100%;
  }

  .trainer .people {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
  .trainer .people .team1 {
    width: 100%;

  }

  .trainer .people .team2 {
    width: 100%;
  }


 
  .contact {
    padding: 6rem;
  }
  .contact .form h2 {
    font-size: 2rem;
    padding-bottom: 7px;
  }

  .contact .form .submit input,
  textarea {
    padding: 12px;
    margin: 10px 0;
  }

  .contact .form .submit input::placeholder {
    font-size: 1rem;
  }
  .contact .form .submit textarea {
    font-size: 1rem;
  }

  .contact .form .btn-send {
    padding: 13px 0;
    margin: 10px 0;
    font-size: 1rem;
  }

  .contact .map h2 {
    font-size: 2rem;
    padding-bottom: 7px;
  }

  .contact .map p {
    font-size: 1rem;
    padding-bottom: 20px;
  }
  .contact .map p i {
    padding-right: 4px;
  }
  .contact .map .contact-box h1{
    padding: 40px 5px 5px 20px;
    color: #ffffff;
    font-size: 1.5rem;
    width: 100%;
  }

  .demo1{
    flex-direction: column;
    height: auto;
  }

  .demo2{
    flex-direction: column;
    height: auto;
  }
  
  .demo3{
    flex-direction: column;
    height: auto;
  }

  .mapsample{
    flex-direction: column;
    height: auto;
    
  }

  
  
  
}

@media screen and (max-width: 768px) {
  .banner-discount{
    height: calc(100vh/4);
  }

  .banner-userapp{
    height: calc(100vh);
  }

  .banner-driverapp{
    height: calc(100vh);
  }


  .logoImg img{
 
    margin-left: 0;
    height:150px;
    width:150px;
    margin:0 ;
  }

  .details {
    padding: 5rem 2rem;
    flex-direction: column;
    justify-content: center;
  }
  .details .one {
    width: 70%;
  }

  .details .one a {
    font-size: 1rem;
  }

  .details .two {
    padding-left: 0;
    border-left: 0;
    border-top: 1px solid #666262;
    width: 70%;
    margin-top: 1.7rem;
    padding-top: 1.7rem;
  }


  .userapp {
    padding: 5rem 2rem;
    flex-direction: column;
    justify-content: center;
  }
  .userapp .one {
    width: 70%;
  }

  .userapp .two {
    width: 70%;
  }

  .taxiapp {
    padding: 5rem 2rem;
    flex-direction: column;
    justify-content: center;
  }
  .taxiapp .one {
    width: 70%;
  }

  .taxiapp .two {
    width: 70%;
  }


  
.taxiapp .two .user .playstore img{
  width: 50%;
  height: 50%;
}



.taxiapp .two .driver .playstore img{
 
  width: 50%;
  height: 50%;

 
}


 

  .trainer {
    padding: 5rem 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .trainer .about {
    width: 70%;
    margin-bottom: 1.7rem;
  }

  .trainer .about h2 {
    font-size: 2.1rem;
  }

  .trainer .about p {
    font-size: 1.1rem;
  }

  .trainer .people {
    width: 80%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .trainer .people .team1 {
    width: 100%;
  }

  .trainer .people .team2 {
    width: 100%;
  }

  

  .contact {
    padding: 5rem 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact .form {
    width: 70%;
  }

  .contact .form h2 {
    font-size: 2.5rem;
  }

  .contact .map {
    width: 70%;
    margin-top: 20px;
    margin: auto;
  }

  .contact .map .contact-box {
    width: 100%;
    height:320px;
  }

  .contact .map .contact-box h1{
    padding: 40px 5px 5px 40px;
    color: #ffffff;
    font-size: 1.2rem;
    width: 100%;
  }
  .start-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .demo1{
    flex-direction: column;
    height: auto;
  }

  .demo2{
    flex-direction: column;
    height: auto;
  }
  
  .demo3{
    flex-direction: column;
    height: auto;
  }
  .mapsample{
    flex-direction: column;
    height: auto;
  }

  
  

}

@media screen and (max-width: 496px) {
  .banner-discount{
    height: calc(100vh/4);
  }

  .banner-userapp{
    height: calc(100vh);
  }


  .banner-driverapp{
    height: calc(100vh);
  }

  .logoImg img{
 
    margin-left: 0;
    height:100px;
    width:100px;
   margin:0 ;
  }
  
 
  .details {
    padding: 4rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .details .one {
    width: 100%;
  }
  .details .two {
    width: 100%;
  }


  .userapp {
    padding: 4rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .userapp .one {
    width: 100%;
  }
  .userapp .two {
    width: 100%;
  }


  
  .taxiapp {
    padding: 4rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .taxiapp .one {
    width: 100%;
  }
  .taxiapp .two {
    width: 100%;
  }


  .taxiapp .two .user .playstore .playstoreimg{
    width: 50%;
    height: 50%;
  }
  
  
  
  .taxiapp .two .driver .playstore .playstoreimg{
    width: 50%;
    height: 50%;
  }

  .content h1{
    font-size: 40px;
  }
  .content h3{ 
    font-size: 30px;  
  }

  .trainer {
    padding: 4rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .trainer .about {
    width: 100%;
  }

  .trainer .people {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .trainer .people .team1 {
    width: 100%;
  }

  .trainer .people .team2 {
    width: 100%;
  }

 
 

  #class .h2 {
    font-size: 1.2rem;
   
  }


  #class .row .team1 {
    width: 225px;
   
  }

  #class .row .team1 p {
    
    font-size: 0.9rem;
    padding: 10px;
  }
  

  .contact {
    padding: 4rem 1rem;
  }
  .contact .form {
    width: 100%;
  }

  .contact .map {
    width: 100%;
  }
  .contact .map .contact-box {
    width: 100%;
    height: 320px;
    
  }
  .contact .map .contact-box h1{
    padding: 40px 5px 5px 40px;
    color: #ffffff;
    font-size: 1.2rem;
    width: 100%;
  }

  .demo1{
    flex-direction: column;
    height: auto;
  }

  .demo2{
    flex-direction: column;
    height: auto;
  }
  
  .demo3{
    flex-direction: column;
    height: auto;
  }

  .mapsample{
  flex-direction: column;
  height: auto;
  
}





  
}


  /*login form*/
.container{
	display: flex;
	justify-content: center;
	margin-top: 25px;
  
}

  .wrapper{
	width: 380px;
	background: #fff;
	border-radius: 15px;
	box-shadow: 0px 15px 20px rgba(0,0,0,0.1);
	
  }
  .wrapper .title{
	font-size: 35px;
	font-weight: 600;
	text-align: center;
	line-height: 100px;
	color: #fff;
	user-select: none;
	border-radius: 15px 15px 0 0;
	background:  #f65606;
  }
  .wrapper form{
	padding: 10px 30px 50px 30px;
  }
  .wrapper form .field{
	height: 50px;
	width: 100%;
	margin-top: 20px;
	position: relative;
  }
  .wrapper form .field input,select,option{
	height: 100%;
	width: 100%;
	outline: none;
	font-size: 17px;
	padding-left: 20px;
	border: 1px solid lightgrey;
	border-radius: 25px;
	transition: all 0.3s ease;
  }
  .wrapper form .field input:focus,
  form .field input:valid{
	border-color: #f65606;
  }
  .wrapper form .field label{
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999999;
	font-weight: 400;
	font-size: 17px;
	pointer-events: none;
	transform: translateY(-50%);
	transition: all 0.3s ease;
  }
  form .field input:focus ~ label,
  form .field input:valid ~ label{
	top: 0%;
	font-size: 16px;
	color: #f65606;
	background: #fff;
	transform: translateY(-50%);
  }
  form .content{
	display: flex;
	width: 100%;
	height: 50px;
	font-size: 16px;
	align-items: center;
	justify-content: space-around;
  }
  form .content .checkbox{
	display: flex;
	align-items: center;
	justify-content: center;
  margin-top: 20px;
  }
  form .content input{
	width: 15px;
	height: 15px;
	background: red;
  }
  form .content label{
	color: #262626;
	user-select: none;
	padding-left: 5px;
  }
  form .content .pass-link{
	color: "";
  }
  form .field input[type="submit"]{
	color: #fff;
	border: none;
	padding-left: 0;
	margin-top: 5px;
	font-size: 20px;
	font-weight: 500;
	cursor: pointer;
	background:  #f65606;
	transition: all 0.3s ease;
  }
  form .field input[type="submit"]:active{
	transform: scale(0.95);
  }
  form .signup-link{
	color: #262626;
	margin-top: 20px;
	text-align: center;
  }
  form .pass-link a,
  form .signup-link a{
	color: #f65606;
	text-decoration: none;
  }
  form .pass-link a:hover,
  form .signup-link a:hover{
	text-decoration: underline;
  }

  
  .eye{
    
    z-index: 9999;
    position: absolute;
    top: 30%;
    right: 20px;

}

#eye{
  color: #999999;
}
#eye-slash{
  color:#999999;
}


/* Avatar */
.avatar {
	display: inline-block;
	margin-bottom: 0;
	height: 3rem;
	width: 3rem;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}
  
.avatar img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
  
  .rounded-circle {
	border-radius: 50% !important;
  }
  
  .btn.focus,
  .btn:focus {
	outline: 0;
	box-shadow: none;
  }
  
  
/* User Profile */

.avatar-profile {
	height: 16rem;
	width: 16rem;
  }
  
  .user-info h4 {
	font-weight: 800;
	color: grey;
	margin-top: 2rem;
  }
  
  .user-info p {
	font-size: 1.1rem;
  }
  
  #edit_profile {
	background-color: #fa9c23;
	border-color: #fa9c23;
  }

/*booking detail*/
  .wrapper-detail {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5rem;
	font-weight: 700;
  }

  

/*booking form*/
.section {
    position: relative;
    height: 900px;
}

.section .section-center {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

#booking {
    font-family: 'Montserrat', sans-serif;
    background-image: url('./personalbg.jpeg');
    background-size: cover;
    background-position: center;
    
}

#booking::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(47, 103, 177, 0.6);
}

.booking-form {
    background-color: #fff;
    padding: 50px 20px;
    -webkit-box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}

.booking-form .form-group {
    position: relative;
    margin-bottom: 30px;
}

.booking-form .form-control {
    background-color: #ebecee;
    border-radius: 4px;
    border: none;
    height: 40px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #3e485c;
    font-size: 14px;
}

.booking-form .form-control::-webkit-input-placeholder {
    color: rgba(62, 72, 92, 0.3);
}

.booking-form .form-control:-ms-input-placeholder {
    color: rgba(62, 72, 92, 0.3);
}

.booking-form .form-control::placeholder {
    color: rgba(62, 72, 92, 0.3);
}

.booking-form input[type="date"].form-control:invalid {
    color: rgba(62, 72, 92, 0.3);
}

.booking-form select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.booking-form select.form-control+.select-arrow {
    position: absolute;
    right: 0px;
    bottom: 4px;
    width: 32px;
    line-height: 32px;
    height: 32px;
    text-align: center;
    pointer-events: none;
    color: rgba(62, 72, 92, 0.3);
    font-size: 14px;
}

.booking-form select.form-control+.select-arrow:after {
    content: '\279C';
    display: block;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.booking-form .form-label {
    display: inline-block;
    color: #3e485c;
    font-weight: 700;
    margin-bottom: 6px;
    margin-left: 7px;
}

.booking-form .submit-btn {
    display: inline-block;
    color: #fff;
    background-color: #f65606;
    font-weight: 700;
    padding: 14px 30px;
    border-radius: 4px;
    border: none;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}

.booking-form .submit-btn:hover,
.booking-form .submit-btn:focus {
    opacity: 0.9;
}

.booking-cta {
    margin-top: 80px;
    margin-bottom: 30px;
}

.booking-cta h1 {
    font-size: 50px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
}

.booking-cta img{
  width: 200px;
  height: 200px;
}

.booking-cta p {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
}

@media(max-width:756px){
  .booking-cta h1 {
    font-size: 25px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    margin-top: 10px;
    font-weight: 700;
}
.booking-cta img{
  width:100px;
  height: 100px;
}
}

.card-confirm{
  overflow-x:auto;
}
h2 span{
  font-size: 25px;
}

/* Confirm Order */
/*
.card-confirm{
  margin: auto;
  max-width: 950px;
  width: 90%;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 1rem;
  border: transparent;
  margin-bottom: 20px;
}
@media(max-width:767px){
  .card{
      margin: 3vh auto;
  }
}
.cart{
  background-color: #fff;
  padding: 4vh 5vh;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
}
@media(max-width:767px){
  .cart{
      padding: 4vh;
      border-bottom-left-radius: unset;
      border-top-right-radius: 1rem;
  }
}

.summary{
  background-color: #ddd;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 4vh;
  color: rgb(65, 65, 65);
}
@media(max-width:767px){
  .summary{
  border-top-right-radius: unset;
  border-bottom-left-radius: 1rem;
  }
}
.summary .col-2{
  padding: 0;
}
.summary .col-10
{
  padding: 0;
}
.row{
  margin: 0;
}
.title b{
  font-size: 1.5rem;
}
.main{
  margin: 0;
  padding: 2vh 0;
  width: 100%;
}
.col-2, .col{
  padding: 0 1vh;
}
a{
  padding: 0 1vh;
}
.close{
  margin-left: auto;
  font-size: 0.7rem;
}*/
/*
img{
  width: 3.5rem;
}*/
.back-to-shop{
  margin-top: 4.5rem;
}
h5{
  margin-top: 4vh;
}
hr{
  margin-top: 1.25rem;
}
form{
  padding: 2vh 0;
}
select{
  border: 1px solid rgba(0, 0, 0, 0.137);
  padding: 1.5vh 1vh;
  margin-bottom: 4vh;
  outline: none;
  width: 100%;
  background-color: rgb(247, 247, 247);
}
input{
  border: 1px solid rgba(0, 0, 0, 0.137);
  padding: 1vh;
  margin-bottom: 4vh;
  outline: none;
  width: 100%;
  background-color: rgb(247, 247, 247);
}
input:focus::-webkit-input-placeholder
{
    color:transparent;
}
.btn-check{
  background-color: #000;
  border-color: #000;
  color: white;
  width: 100%;
  font-size: 0.7rem;
  margin-top: 4vh;
  padding: 1vh;
  border-radius: 0;
}
.btn-check:focus{
  box-shadow: none;
  outline: none;
  box-shadow: none;
  color: white;
  -webkit-box-shadow: none;
  -webkit-user-select: none;
  transition: none; 
}
.btn-check:hover{
  color: white;
}
a{
  color: black; 
}
a:hover{
  color: black;
  text-decoration: none;
}
#code{
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0.253) , rgba(255, 255, 255, 0.185)), url("https://img.icons8.com/small/16/000000/long-arrow-right.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
}

/*payment*/


.btn.btn-primary-pay {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  background-image: linear-gradient(to right, #f65606 0%, 	#FFA500 51%, #f65606 100%);
  border: none;
  transition: 0.5s;
  background-size: 200% auto;

}


.btn.btn.btn-primary-pay:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}



.btn.btn-primary-pay:hover .fas.fa-arrow-right {
  transform: translate(15px);
  transition: transform 0.2s ease-in;
}

.pay-title{
  font-size: 35px;
	font-weight: 600;
	text-align: center;
	color: #f65606;
	user-select: none;
}



/*tabs*/

#tile-1 .tab-pane
{
  padding:15px;
  height:80px;
}
#tile-1 .nav-tabs{
  position:relative;
  border:none!important;
  background-color:#f65606;
/*   box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2); */
  border-radius:6px;
}
#tile-1 .nav-tabs li{
  margin:0px!important;
}
#tile-1 .nav-tabs li .nav-link{
  position:relative;
  margin-right:0px!important;
  padding: 20px 40px!important;
  font-size:16px;
  border:none!important;
  color:#fff;
}

@media(max-width:680px){
  #tile-1 .nav-tabs li .nav-link{
   
    padding: 10px 20px!important;

  }
}

@media(max-width:515px){
  #tile-1 .nav-tabs li .nav-link{
   
    padding: 10px 10px!important;
  
  }
}

@media(max-width:450px){
  #tile-1 .nav-tabs li .nav-link{
   
    padding: 5px 5px!important;
    font-size: 10px;
  }
}

#tile-1 .nav-tabs .active{
  background-color:#fff!important;
  border:none;
  border:none!important;
  color:#f65606!important;
}

/*card books*/
.card-book{
	max-width: 300px;
	border-radius: 10px;
  background-color: rgb(247, 249, 249 );
  margin:10px 10px;

}

.card-img-top{
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
span.text-muted{
	font-size: 12px;
}
small.text-muted{
	font-size: 8px;
}
h5.mb-0{
	font-size: 1rem;
}
small.ghj{
	font-size: 9px;
}
.mid{
	background: #ECEDF1;
}
h6.ml-1{
	font-size: 13px;
}
small.key{
	text-decoration: underline;
	font-size: 9px;
	cursor: pointer;
}
.btn-danger{
	color: #FFCBD2;
}
.btn-danger:focus{
	box-shadow: none;
}
small.justify-content-center{
	font-size: 9px;
	cursor: pointer;
	text-decoration: underline;
}

@media screen and (max-width:600px){
    .col-sm-4{
        margin-bottom: 50px;
    }
}




  
/* Reviews */

.rating {
  margin-top: 10rem;
}

.stars {
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 0;
}

.star {
  display: inline;
  list-style: none;
  font-size: 3rem;
  padding-left: 0.9rem;
  color: #e3e3e3;
}

.star:first-child {
  padding-left: 0;
}

.orange {
  color: #fa9c23;
}

.yellow {
  color: #fdcc0d;
}

.review_user {
  font-size: 0.8rem;
  color: grey;
}



/* Ratings */

.rating-outer {
  display: inline-block;
  position: relative;
  font-family: FontAwesome;
  color: #fdcc0d;
}

.rating-outer::before {
  content: '\f006 \f006 \f006 \f006 \f006';
}

.rating-inner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  font-family: FontAwesome;
  overflow: hidden;
  width: 0;
}

.rating-inner::before {
  content: '\f005 \f005 \f005 \f005 \f005';
  color: #f8ce0b;
}



/*review table*/
.wp-table tr:nth-child(odd) {
  background-color: #fff;
}

.wp-table tr:nth-child(even) {
  background-color: #f1f1f1;
}

.wp-table tr {
  border-bottom: 1px solid #ddd;
}

.wp-table th:first-child, 
.wp-table td:first-child {
  padding-left: 16px;
}


.wp-table td, 
.wp-table th {
  padding: 8px 8px;
  display: table-cell;
  text-align: left;
  vertical-align: top;
}

.wp-table th {
  font-weight: bold;
}

.wp-table {
  font-size: 13px!important;
  border: 1px solid #ccc;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table;
}



/*pr*/
#msform{
  text-align: center;
  position: relative;
  margin-top: 20px;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey
}

#progressbar .active {
  color: #f65606
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 33.3%;
  float: left;
  position: relative;
  font-weight: 400
}


#progressbar #package:before {
  font-family: FontAwesome;
  content: "\f02d"
}

#progressbar #account:before {
  font-family: FontAwesome;
  content: "\f041"
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: "\f007"
}


#progressbar #confirm:before {
  font-family: FontAwesome;
  content: "\f09d"
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #f65606
}


.progress-bar {
  background-color: #f65606
}


/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.btn-label{

  padding:2px 5px;
  bottom: 50px;
  right: 120px;
  position: fixed;
  z-index: 100;
  user-select:none;
  
  background-color: black;
  color:white;
  border-radius: 3px;
  box-shadow: 10px 10px 5px #aaaaaa;
  
  }

/* for mobile */
@media screen and (max-width: 767px) {

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
  .btn-label{

   
    bottom: 25px;
    right: 55px;
    font-size: 12px;
   
    
    }
}



/*packagecard*/
.container-package {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 

}
.badge {
  text-transform: uppercase;
  color: #f65606;
  letter-spacing: 1px;
  font-size: 11px;
}
.container-package .heading {
  font-size: 26px;
  margin: 20px 0;
  text-align: left;
}
.card_group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.pricing-card {
  margin: 20px 30px;
  height: 400px;
  width: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: 0.5s ease-in-out;
  padding: 16px 14px;
  border-radius: 10px;
  border: 2px solid #f65606;
}
.pricing-card i {
  color: #f65606;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  border-radius: 50%;
  box-shadow: 0 0 34px -12px gray;
}
.pricing-card span {
  color: #f65606;
  margin: -10px 0;
  font-weight: bold;
  font-size: 14px;
}
.price {
  font-size: 30px;
  font-family: "Baloo 2";
}
.pricetotal {
  font-size: 30px;
  font-family: "Baloo 2";
  color: #f65606;
}

.pricestrike {
  font-size: 20px;
  
}
.pricestrike .offer{
  background-color: red;
  color: #ffffff;
  font-size: 15px;
}

.package_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}
.package_list li {
  list-style: none;
  margin: 6px 0;
  color: gray;
  font-size: 14px;
}
.get_started_btn {
  border: 2px solid #f65606;
  color: white;
  background: #f65606;
  padding: 8px 25px;
  border-radius: 20px;
  text-decoration: none;
  font-weight: bold;
  transition: 0.3s ease-in-out;
}
.get_started_btn:hover {
  background: transparent;
  color: #f65606;
}
@media screen and (max-width: 730px) {

  .heading {
    font-size: 10px;
    
  }
}




/*use booking*/
.pricing-content{position:relative;}
.pricing_design{
    position: relative;
    margin: 10px 15px;
}
.pricing_design .single-pricing{
    padding: 60px 40px;
    border-radius:30px;
    box-shadow: 0 10px 40px -10px rgba(0,64,128,.2);
    position: relative;
  
}
.pricing_design .single-pricing:before{
    content: "";
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 18px 18px 190px 18px;
    border: 1px solid #eee;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    
}

.price-head h2 {
	margin-bottom: 20px;
	font-size: 26px;
	font-weight: 600;
  
}
.price-head h1 {
	font-weight: 600;
	margin-top: 30px;
	margin-bottom: 5px;
}


.single-pricing ul{list-style:none;margin-top: 30px;}
.single-pricing ul li {
	line-height: 36px;
}
.single-pricing ul li i {
	background: #f65606;
	color: #fff;
	width: 20px;
	height: 20px;
	border-radius: 30px;
	font-size: 11px;
	text-align: center;
	line-height: 20px;
	margin-right: 6px;
}


.price_btn {
	background: #f65606;
	padding: 10px 30px;
	color: #fff;
	display: inline-block;
	margin-top: 20px;
	border-radius: 2px;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}
.price_btn:hover{background:#f65606;}
a{
text-decoration:none;    
}

.section-title {
    margin-bottom: 60px;
}
.text-center {
    text-align: center!important;
}

.section-title h2 {
    font-size: 45px;
    font-weight: 600;
    margin-top: 0;
    position: relative;
    text-transform: capitalize;
}


#btn-block{
  background-color: #f65606;
  color: #ffffff;
}






/*driver dashboard*/
.order-card {
  color: #fff;
}

.bg-c-blue {
  background: linear-gradient(45deg,#4099ff,#73b4ff);
}

.bg-c-green {
  background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}

.bg-c-yellow {
  background: linear-gradient(45deg,#FFB64D,#ffcb80);
}

.bg-c-pink {
  background: linear-gradient(45deg,#FF5370,#ff869a);
}


.card-dash {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card-dash .card-block {
  padding: 25px;
}

.order-card i {
  font-size: 26px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}



ul { list-style-type: none; }


/*driver completed*/

#ads {
  margin: 30px 0 30px 0;
 
}

#ads .card-notify-badge {
      position: absolute;
      left: -10px;
      top: -20px;
      background: #f65606;
      text-align: center;
      border-radius: 30px 30px 30px 30px; 
      color: white;
      padding: 5px 10px;
      font-size: 14px;

  }

  #ads .card-notify-badge-pay {
    position: absolute;
    right: -10px;
    top: -20px;
    background: #f65606;
    text-align: center;
    border-radius: 30px 30px 30px 30px; 
    color: white;
    padding: 5px 10px;
    font-size: 14px;

}

#ads .card-notify-year {
      position: absolute;
      right: -10px;
      top: -20px;
      background: #ff4444;
      border-radius: 50%;
      text-align: center;
      color: #fff;      
      font-size: 14px;      
      width: 50px;
      height: 50px;    
      padding: 15px 0 0 0;
}


#ads .card-detail-badge {      
      background: #f65606;
      text-align: center;
      border-radius: 30px 30px 30px 30px;
      color: white;
      padding: 5px 10px;
      font-size: 14px;        
  }

 

#ads .card:hover {
          background: #fff;
          box-shadow: 12px 15px 20px 0px rgba(46,61,73,0.15);
          border-radius: 4px;
          transition: all 0.3s ease;
      }

#ads .card-image-overlay {
      font-size: 20px;
      
  }


#ads .card-image-overlay span {
          display: inline-block;              
      }



#ads .ad-btn {
      text-transform: uppercase;
      width: 150px;
      height: 40px;
      border-radius: 80px;
      font-size: 16px;
      line-height: 35px;
      text-align: center;
      border: 3px solid #f65606;
      display: block;
      text-decoration: none;
      margin: 20px auto 1px auto;
      color: white;
      overflow: hidden;        
      position: relative;
      background-color: #f65606;
  }

#ads .ad-btn:hover {
          background-color: #f65606;
          color: #1e1717;
          border: 2px solid #f65606;
          background: transparent;
          transition: all 0.3s ease;
          box-shadow: 12px 15px 20px 0px rgba(46,61,73,0.15);
      }

#ads .ad-title h5 {
      text-transform: uppercase;
      font-size: 18px;
      color: #000;
  }




/*admin dashboard*/

.sidebar-wrapper {
	display: flex;
	width: 100%;
	align-items: stretch;
	min-height: 100vh;
	font-weight: normal;
  
	margin-top: 0;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 10;
  }
  #sidebar {
	min-width: 100%;
	max-width: 100%;
	background: #232f3e;
	color: #fff;
	transition: all 0.3s;
  }
  #sidebar.active {
	margin-left: -250px;
  }
  
  #sidebar .sidebar-header {
	padding: 20px;
	background: #232f3e;
  }
  #sidebar ul.components {
	padding: 5px 0px;
	border-bottom: 1px solid #232f3e;
  }
  
  #sidebar ul li a {
	padding: 10px;
	font-size: 1.1em;
	display: block;
	color: white;
	text-decoration: none;
  }
  #sidebar ul li a:hover {
	color: #232f3e;
	background: #fff;
  }
  
  #sidebar ul li div.dropdown-menu a{
	color: black;
  
  }
  
  #sidebar ul li a i {
	margin-right: 0.3rem;
  }
  
  #sidebar ul li.active > a,
  a[aria-expanded='true'] {
	color: #fff;
	background: #232f3e;
  }
  a[data-toggle='collapse'] {
	position: relative;
  }
  .dropdown-toggle::after {
	display: block;
	position: absolute;
	top: 50%;
	right: 20px;
	transform: translateY(-50%);
  }
  
  ul ul a {
	font-size: 1rem !important;
	padding-left: 30px !important;
	background: #232f3e;
  }
  
  ul.CTAs {
	padding: 20px;
  }
  
  ul.CTAs a {
	text-align: center;
	font-size: 0.9em !important;
	display: block;
	border-radius: 5px;
	margin-bottom: 5px;
  }
  
  #update-btn:disabled{
    cursor: not-allowed;
    pointer-events: all !important;
  }

 
  .policy{
    margin:50px;
  }

  .policy h1{
    text-align: center;
  }
  .delete_request_button{
    background-color: #f65606;
    color: #ffffff;
  }



  .policy h4{
    margin-left: 20px;
    justify-content: left;
    margin-top: 50px;
  }

  .policy p{
    margin-left: 50px;
    justify-content: center;
    text-align: justify;
  }

  .policy p ul li{
    list-style-type: lower-roman;
  }

  @media screen and (max-width: 684px) {

    .policy{
      margin:25px;
    }
  
    .policy h1{
      font-size: 20px;
    }
    .policy h4{
      margin-left: 10px;
      margin-top: 25px;
      font-size: 14px;
    }
  
    .policy p{
      margin-left: 25px;
      font-size: 12px;
     
    }
    
  }


  .terms{
    margin:50px;
  }

  .terms h1{
    text-align: center;
  }

  .terms h4{
    margin-left: 20px;
    justify-content: left;
    margin-top: 50px;
  }

  .terms p{
    margin-left: 50px;
    justify-content: center;
    text-align: justify;
  }

  .terms p ul li{
    list-style-type: lower-roman;
  }

  @media screen and (max-width: 684px) {

    .terms{
      margin:25px;
    }
  
    .terms h1{
      font-size: 20px;
    }
    .terms h4{
      margin-left: 10px;
      margin-top: 25px;
      font-size: 14px;
    }
  
    .terms p{
      margin-left: 25px;
      font-size: 12px;
     
    }
  }


  .refund{
    margin:50px;
  }

  .refund h1{
    text-align: center;
  }

  .refund h4{
    margin-left: 20px;
    justify-content: left;
    margin-top: 50px;
  }

  .refund p{
    margin-left: 50px;
    justify-content: center;
    text-align: justify;
  }

  

  @media screen and (max-width: 684px) {

    .refund{
      margin:25px;
    }
  
    .refund h1{
      font-size: 20px;
    }
    .refund h4{
      margin-left: 10px;
      margin-top: 25px;
      font-size: 14px;
    }
  
    .refund p{
      margin-left: 25px;
      font-size: 12px;
     
    }
  }

